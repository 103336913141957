import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import {
  Paper,
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  CardMedia,
  Typography,
  Button,
  SpeedDial,
  SpeedDialIcon,
  SpeedDialAction,
  Grid,
  Box,
  Stack,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions, 
  SwipeableDrawer,
  Container
} from "@mui/material";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Icons for SpeedDial - replace these with specific icons you need
import ShareIcon from "@mui/icons-material/Share";
import HealthAndSafetyIcon from "@mui/icons-material/HealthAndSafety";
import ChatIcon from "@mui/icons-material/Chat";
import Navbar from "../components/Navbar";
import SocialMediaLinks from "../components/SocialMediaLinks";
import "../components/SliderStyles.css";

import SignIn from "./SignIn";

import SignupForm from "../components/SignupForm";

import logo from "../assets/images/logos/logo.png";
import ads from "../assets/logo.png";
import favicon from "../assets/favicon.png";
import bg2 from "../assets/bg2.jpg"
import bg6 from "../assets/bg6_s.jpg"
import bg7 from "../assets/bg7.jpg"
import bg8 from "../assets/bg8_s.jpg"
const actions = [
  { icon: <ShareIcon />, name: "Share" },
  { icon: <HealthAndSafetyIcon />, name: "Our Community" },
  // Add other actions here
  { icon: <ChatIcon />, name: "Talk to Us" },
];

const testimonials = [
  {
    quote:
      "Using this platform has revolutionized the way our team approaches development. The intuitive design and robust features made it easy for us to integrate our projects seamlessly.",
    author: "Jamie Lannister",
    position: "Lead Developer",
    company: "Westeros Inc.",
  },
  {
    quote:
      "I was amazed by the level of support and community engagement I found here. It's not just about the tools; it's the people behind them that make the difference.",
    author: "Sansa Stark",
    position: "Product Manager",
    company: "North Enterprises",
  },
  {
    quote:
      "Transitioning to using these services has been a game changer for our productivity and workflow efficiency. Highly recommend to any teams looking to scale their development efforts.",
    author: "Tyrion Lannister",
    position: "CTO",
    company: "Lannister Solutions",
  },
  {
    quote:
      "As a freelance developer, finding a platform that I can rely on for all my projects has been a challenge. This service has met all my needs and exceeded my expectations in terms of usability and performance.",
    author: "Arya Stark",
    position: "Freelance Developer",
    company: "Independent",
  },
  {
    quote:
      "The attention to detail and the commitment to open-source principles have made this my go-to platform for development. It's rare to find such a powerful tool that's also community-driven.",
    author: "Daenerys Targaryen",
    position: "Software Engineer",
    company: "DragonTech",
  },
  // Add more testimonials as needed
];

const cardData = [
  {
    title: "Real-Time Market Data",
    content:
      "Access up-to-date market data and trends to make informed trading decisions.",
    image: bg7,
    action: "View Market Trends",
    actionLink: "/market",
  },
  {
    title: "Commodity Trading",
    content:
      "Trade a variety of commodities from grains to metals anywhere, anytime.",
    image: bg8,
    action: "Start Trading",
    actionLink: "/trade",
  },
  {
    title: "Portfolio Management",
    content:
      "Manage and review your investment portfolio to keep track of your assets and their performance.",
    image: bg6,
    action: "Manage Portfolio",
    actionLink: "/portfolio",
  },
  // Add more card data as needed
];

const Homepage = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
  };

  const navigate = useNavigate();

  const navbarHeight = 64;

  const [openModal, setOpenModal] = useState(false);

  const [open, setOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");

  const handleOpen = () => setOpenModal(true);
  const handleClose = () => setOpenModal(false);

  const handleSignInWithEmail = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const email = formData.get("email");
    const password = formData.get("password");

    const endpoint = email.endsWith("@accentrixcloud.com")
      ? "https://theyard.accentrixcloud.com/api/accounts"
      : "https://theyard.accentrixcloud.com/api/users";
    const profileRoute = email.endsWith("@accentrixcloud.com")
      ? "/admin-profile"
      : "/user-profile";

    try {
      const response = await axios.post(
        `https://theyard.accentrixcloud.com${endpoint}`,
        { email, password }
      );
      if (response.status === 200) {
        navigate(profileRoute, { state: { accountDetails: response.data } });
      } else {
        throw new Error("Authentication failed");
      }
    } catch (error) {
      console.error("Sign in error:", error);
      setDialogMessage(`An error occurred while signing in: ${error.message}`);
      setOpen(true);
    }
  };

  const bannerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: "300px",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundImage: `url(${bg2})`,
    position: "relative", // Needed for absolute positioning of the overlay
  };
  

  const overlayStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(0,0,0,0.5)", // Adjust color and opacity here
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  };

  const textStyle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    position: "relative",
    textAlign: "center",
    paddingTop: "20px",
  };

  const subtitle = {
    zIndex: 2, // Ensures text is above the overlay
    color: "white",
    position: "relative",
    textAlign: "center",
    marginBottom: "20px",
  };

  const logoStyle = {
    position: "absolute", // Absolute positioning within the relative banner
    
    height: "80px",
  };

  return (
    <div>
      <Helmet>
      <title>Kenya's Commodities Exchange Hub: The Yard - Trading House</title>
      <meta name="description" content="Kenya's Commodities Exchange Hub: The Yard - Trading House" />
      <link rel="icon" href={favicon} type="image/png" />
      </Helmet>
        <div style={bannerStyle}>
        
        <div style={overlayStyle}></div>

        <Typography variant="h3" style={textStyle}>
          The Yard - Trading House
        </Typography>
        <Typography variant="h5" style={subtitle}>
          Kenya's Commodities Exchange Hub
        </Typography>
        <Button
          variant="contained"
          onClick={handleOpen}
          onClose={handleClose}
          color="secondary"
        >
          Log In
        </Button>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{ paddingTop: "20px" }}
        >
          <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 300, // or whatever width you want
          boxShadow: 24,
          p: 4,
        }}
      >
        <SignIn handleSignInWithEmail={handleSignInWithEmail} />
      </Box>
        </Modal>
      </div>
      <Container
        style={{
          padding: 20,
          color: "#FFF",
          minHeight: `calc(100vh - ${navbarHeight}px)`,
        }}
      >
        <Grid container spacing={2} justifyContent="space-between">
        <Grid item xs={12} sm={4} md={6}>
    <Grid container spacing={2} justifyContent="space-between" sx={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
      <Typography color="primary" variant="h4" style={{ fontWeight: "lighter", margin: "20px 20px" }}>
        About The Yard
      </Typography>
      <Typography color="#666" variant="body1" style={{ margin: "0 20px 0 20px", lineHeight: "1.6", textAlign:'justify' }}>
        The Yard is Kenya's premier commodities trading house, offering a trusted platform 
        for buyers and sellers to trade a wide variety of goods, from grains and produce to 
        metals and raw materials. With real-time market data and seamless portfolio management, 
        The Yard is your gateway to efficient and transparent commodity exchange. <br /><br />Discover more
        opportunities with <strong><span style={{color:'#ff4081'}} >accentrixcloud</span></strong> and make informed decisions today.
      </Typography>
      <Button
        variant="contained"
        color="secondary"
        onClick={() => window.open("https://accentrixcloud.com", "_blank")}
        style={{ marginTop: "20px" }}
      >
        Explore
      </Button>
    </Grid>
  </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            <SignupForm />
          </Grid>
        </Grid>

        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} sm={4} style={{ color: "#3f51b5", textAlign:'center' }}>
          <img src={ads} alt="Logo" style={{height: 80}} />
            <Typography >
            A product of Accentrix Data Systems Ltd
            </Typography>
            <Typography variant="caption" >All Rights Reserved  © {new Date().getFullYear()} </Typography>
          </Grid>

          <Grid item xs={12} sm={4}>
            <div
              style={{
                flexGrow: 0.1,
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/privacy"
              >
                Privacy
              </Typography>
              <Typography
                style={{ color: "#3f51b5", textDecoration: "none" }}
                component={Link}
                to="/terms"
              >
                Terms of Use
              </Typography>
            </div>
          </Grid>

          <Grid item xs={12} sm={4} style={{flexGrow: 0.1, display: "flex", justifyContent: "center", flexDirection: "column", justifyContent: "center", alignItems: "center",}} >
            <SocialMediaLinks />
          </Grid>
        </Grid>
      </Container>
      
    </div>
  );
};

export default Homepage;
