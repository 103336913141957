import React, { useState } from "react";
import axios from "axios";
import {
  Button,
  TextField,
  Container,
  Box,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Link,
} from "@mui/material";

function SignupForm() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phoneNumber: "",
    organization: "",
  });
  const [forgotPassword, setForgotPassword] = useState(false); // Toggle for Forgot Password
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [activationLink, setActivationLink] = useState("");

  const handleChange = (event) => {
    const { name, value } = event.target;
  
    // Handle phone number validation
    if (name === "phoneNumber") {
      let phone = value.replace(/\D/g, ""); // Remove non-digit characters
  
      if (phone.startsWith("0")) {
        // Replace starting `0` with `254`
        phone = "254" + phone.slice(1);
      } else if (!phone.startsWith("254")) {
        // Ensure number always starts with `254`
        phone = "254" + phone;
      }
  
      // Limit to 12 digits
      if (phone.length > 12) {
        phone = phone.slice(0, 12);
      }
  
      setFormData((prevState) => ({
        ...prevState,
        phoneNumber: phone,
      }));
  
      // Validate phone length
      if (phone.length !== 12) {
        setPhoneError("Phone number must be exactly 12 digits.");
      } else {
        setPhoneError(""); // Clear error if valid
      }
      return;
    }
  
    // Handle email validation
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      setEmailError(emailRegex.test(value) ? "" : "Invalid email address");
    }
  
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      const response = await axios.post(
        "https://theyard.accentrixcloud.com/api/users",
        formData
      );
      setActivationLink(response.data.activationLink);
      setModalMessage(
        "Account created successfully. Please check your email for the activation link."
      );
      setIsError(false);
      setShowModal(true);
    } catch (error) {
      setModalMessage(
        `Failed to create account: ${
          error.response ? error.response.data.message : error.message
        }`
      );
      setIsError(true);
      setShowModal(true);
    } finally {
      setLoading(false);
    }
  };

  const handleForgotPasswordSubmit = async (event) => {
    event.preventDefault();
    try {
      setModalMessage(
        `A password reset link has been sent to ${formData.email}.`
      );
      setIsError(false);
      setShowModal(true);
    } catch (error) {
      setModalMessage(
        "Failed to process your request. Please try again later."
      );
      setIsError(true);
      setShowModal(true);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <Container maxWidth="sm">
      {!forgotPassword ? (
        <>
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            color="#3f51b5"
            alignSelf="center"
          >
            Create Account
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              autoFocus
              value={formData.name}
              onChange={handleChange}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              error={!!emailError}
              helperText={emailError}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              id="phoneNumber"
              label="Phone Number"
              name="phoneNumber"
              autoComplete="tel"
              value={formData.phoneNumber}
              onChange={handleChange}
              error={!!phoneError}
              helperText={phoneError || "Enter a phone number starting with 254"}
            />
          <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}> 
          <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : "Register"}
            </Button>
            <Typography color="#666" variant="body2" align="center">
              Already have an account?{" "}
              <Link href="#" onClick={() => window.scrollTo(0, 0)}>
                Click here to log in
              </Link>
            </Typography>
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              <Link
                href="#"
                onClick={() => setForgotPassword(true)}
                sx={{ color: "secondary.main" }}
              >
                Forgot Password?
              </Link>
            </Typography>
          </Box>

    
          </Box>
        </>
      ) : (
        <>
          <Typography
            variant="h5"
            component="h1"
            gutterBottom
            color="#3f51b5"
            align="center"
          >
            Forgot Password
          </Typography>
          <Box
            component="form"
            onSubmit={handleForgotPasswordSubmit}
            noValidate
            sx={{ mt: 1 }}
          >
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Please enter your email address"
              name="email"
              autoComplete="email"
              value={formData.email}
              onChange={handleChange}
              error={!!emailError}
              helperText={emailError}
            />
            <Box sx={{display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}> 
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              sx={{ mt: 3, mb: 2 }}
              disabled={loading}
            >
              Submit
            </Button>
            <Typography color="#666" variant="body2" align="center">
              Remembered your password?{" "}
              <Link href="#" onClick={() => setForgotPassword(false)}>
                Back to Signup
              </Link>
            </Typography>
            </Box>
          </Box>
        </>
      )}

      {/* Modal for showing the server response */}
      <Dialog open={showModal} onClose={handleCloseModal}>
        <DialogTitle>{isError ? "Error" : "Success"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {modalMessage}
            {activationLink && (
              <>
                <br />
                Activation Link: <a href={activationLink}>{activationLink}</a>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
}

export default SignupForm;
