import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Typography,
  CircularProgress,
  Paper,
  Divider,
  IconButton,
  TextField,
  Button,
  Modal,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  SwipeableDrawer,
  IconButton as MuiIconButton,
  Badge,
  Container,
  Grid,
} from "@mui/material";

import { styled, ThemeProvider, createTheme } from "@mui/material/styles";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import ShareIcon from "@mui/icons-material/Share";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import axios from "axios";

import { useUser } from "../UserContext";
import UserMenu from "./UserMenu";

import Copyright from "./Copyright";


const drawerWidth = 240;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: 'flex-end',
}));

const defaultTheme = createTheme();

export default function CommodityDetails() {
  const { user } = useUser();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const { id } = useParams(); // Extract ID from URL
  const [commodity, setCommodity] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [buyModalOpen, setBuyModalOpen] = useState(false);
    const [userPhone, setUserPhone] = useState("");
    const [buyMessage, setBuyMessage] = useState(""); // To show the status message after purchase
    const [shareModalOpen, setShareModalOpen] = useState(false);
    const [shareMessage, setShareMessage] = useState("");
    const [amount, setAmount] = useState("");
    const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [selectedCommodity, setSelectedCommodity] = useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    // Fetch the commodity details
    const fetchCommodityDetails = async () => {
      try {
        const response = await axios.get(
          `https://theyard.accentrixcloud.com/api/commodities/${id}`
        );
        setCommodity(response.data);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching commodity details:", err);
        setError("Failed to load commodity details.");
        setLoading(false);
      }
    };

    fetchCommodityDetails();
  }, [id]);


  const handleBuy = (commodity) => {
    setSelectedCommodity(commodity)
    setBuyModalOpen(true); // Open the modal for purchase
  };

  const triggerSTKPush = async () => {
    if (!userPhone) {
      setBuyMessage("Phone number is required to complete the purchase.");
      return;
    }
  
    try {
      // Trigger STK Push for payment
      const response = await axios.post("https://theyard.accentrixcloud.com/stk_push", {
        phone_number: userPhone,
        amount: commodity.price,
      });
  
      console.log("STK Push initiated:", response.data);
      setBuyMessage(`Purchase initiated for ${commodity.name}. Follow the prompts on your phone.`);
      setBuyModalOpen(false); // Close modal after successful initiation
    } catch (error) {
      console.error("Error initiating purchase:", error);
      setBuyMessage("Failed to complete the purchase. Please try again.");
    }
  };
  

  const shareOnPlatform = (platform) => {
    const message = `Check out this trade: ${commodity.name} - Over ${commodity.qty} ${commodity.uom} available at Ksh ${formatPrice(commodity.price)} per unit. Click the link to purchase: https://trading.accentrixcloud.com/trades/${commodity.id}`;
    const encodedMessage = encodeURIComponent(message);
  
    let url = "";
    switch (platform) {
      case "whatsapp":
        url = `https://wa.me/?text=${encodedMessage}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedMessage}`;
        break;
      case "tiktok":
        url = `https://www.tiktok.com/share?text=${encodedMessage}`;
        break;
      default:
        setShareMessage("Unsupported platform. Please choose WhatsApp, Facebook, or TikTok.");
        return;
    }
  
    window.open(url, "_blank");
    setShareMessage(`Shared successfully on ${platform.charAt(0).toUpperCase() + platform.slice(1)}!`);
    setShareModalOpen(false); // Close modal after sharing
  };


  if (error)
    return (
      <Typography
        color="error"
        sx={{ textAlign: "center", mt: 4 }}
      >
        {error}
      </Typography>
    );

    const calculateQuantity = () => {
        if (!amount || !commodity) return 0; // Use `commodity`, not `setCommodity`
        const unitPrice = commodity.price; 
        return (parseFloat(amount) / unitPrice).toFixed(2); // Calculate quantity and round to 2 decimals
    };

      const formatPrice = (price) => {
        return parseFloat(price).toLocaleString("en-US", { minimumFractionDigits: 2 });
      };

      const handleBack = () => {
        navigate(-1); // Go back to the previous page
      };

      const handlePurchase = async () => {
        if (!userPhone || !amount) {
          setSnackbarMessage("Phone number and amount are required.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
          return;
        }
    
        try {
          await axios.post("https://theyard.accentrixcloud.com/stk_push", {
            phone_number: userPhone,
            amount: amount,
          });
    
          setSnackbarMessage(`Purchase initiated for ${commodity.name}.`);
          setSnackbarSeverity("success");
          setSnackbarOpen(true);
          setBuyModalOpen(false); // Close the modal
        } catch (err) {
          console.error("Purchase failed:", err);
          setSnackbarMessage("Failed to complete the purchase. Try again.");
          setSnackbarSeverity("error");
          setSnackbarOpen(true);
        }
      };
    
      const handleShare = () => {
        setShareModalOpen(true); // Open the Share modal
      };

     
    
      if (loading)
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        );

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              The Yard - Trading House
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <SwipeableDrawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            '& .MuiDrawer-paper': {
              width: drawerWidth,
              boxSizing: 'border-box',
            },
          }}
          anchor="left"
          open={open}
          onClose={handleDrawerClose}
          onOpen={handleDrawerOpen}
        >
          <DrawerHeader>
          <Typography>{user.username}</Typography>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </DrawerHeader>
          <UserMenu />
        </SwipeableDrawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === "light"
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: "100vh",
            overflow: "auto",
            
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3} sx={{ display:'flex', flexDirection:'column', justifyContent:'center', alignItems:'center'}}>
              <Grid item xs={12} md={8} lg={9} >
              
              <Paper sx={{ p: 3 }}>
                <Box display="flex">
                <IconButton color="secondary" onClick={handleBack}>
                <ArrowBackIcon />
            </IconButton>   
                    <Typography color="primary" variant="h4">{commodity.name}</Typography>
                </Box>
                    <Divider sx={{ mb: 2 }} />
                    <Typography color="secondary" variant="body1">
                    <strong>Quantity:</strong> {commodity.qty} {commodity.uom}
                    </Typography>
                    <Typography color="secondary" variant="body1">
                    <strong>Price:</strong> Ksh {formatPrice(commodity.price)} per {commodity.uom}
                    </Typography>
                    <Divider sx={{ mb: 2 }} />
                    <Typography color="#666" variant="body1" gutterBottom>
                        {commodity.qty} {commodity.uom} of {commodity.name} is available for purchase at a price of Ksh {commodity.price} per {commodity.uom}.{" "}
                        Please proceed with your required purchase.
                        </Typography>
                </Paper>
                <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          mt: 4,
        }}
      >
        <IconButton
            onClick={() => handleBuy(commodity)} 
          sx={{ bgcolor: "#1976d2", color: "white", "&:hover": { bgcolor: "#145a9c" } }}
        >
          <ShoppingCartIcon />
        </IconButton>
        <IconButton
          onClick={handleShare}
          sx={{ bgcolor: "#4caf50", color: "white", "&:hover": { bgcolor: "#3a873a" } }}
        >
          <ShareIcon />
        </IconButton>
      </Box>
      {/* Purchase Modal */}
      <Modal open={buyModalOpen} onClose={() => setBuyModalOpen(false)}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h6" mb={2}>
            Complete Purchase
          </Typography>
          <TextField
            label="Phone Number"
            fullWidth
            value={user.phoneNumber}
            onChange={(e) => setUserPhone(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            label="Amount"
            fullWidth
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            sx={{ mb: 2 }}
          />
                {amount && selectedCommodity && (
            <Typography sx={{ mt: 1, color: "text.secondary" }}>
                Quantity: {calculateQuantity()} {selectedCommodity.uom}
            </Typography>
            )}
          <Button
            onClick={handlePurchase}
            sx={{ bgcolor: "primary.main", color: "white", "&:hover": { bgcolor: "primary.dark" } }}
            fullWidth
          >
            Confirm Purchase
          </Button>
        </Box>
      </Modal>

      {/* Share Modal */}
      <Modal open={shareModalOpen} onClose={() => setShareModalOpen(false)}>
        <Box sx={{ ...style, width: 400 }}>
          <Typography variant="h6" mb={2}>
            Share Commodity
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
            }}
          >
            <IconButton sx={{ color: "#25D366" }} onClick={() => shareOnPlatform("whatsapp")}>
              <i className="fab fa-whatsapp"></i>
            </IconButton>
            <IconButton sx={{ color: "#4267B2" }} onClick={() => shareOnPlatform("facebook")}>
              <i className="fab fa-facebook"></i>
            </IconButton>
            <IconButton sx={{ color: "#000000" }} onClick={() => shareOnPlatform("tiktok")}>
              <i className="fab fa-tiktok"></i>
            </IconButton>
          </Box>
        </Box>
      </Modal>
              </Grid>
            </Grid>
            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
    
  );
  
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };