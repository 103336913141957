import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableContainer,
  Hidden,
  Paper,
  IconButton,
  Link,
  CircularProgress
} from "@mui/material";
import ShareIcon from "@mui/icons-material/Share";
import axios from "axios";
import { useUser } from "../UserContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function Trades() {
  const [open, setOpen] = useState(false);
  const [shareModalOpen, setShareModalOpen] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [commodities, setCommodities] = useState([]);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState("");
  const [selectedCommodity, setSelectedCommodity] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useUser();

  const fetchCommodities = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        "https://theyard.accentrixcloud.com/api/commodities"
      );
      setCommodities(response.data || []);
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch commodities:", error);
      setLoading(false);
      setCommodities([]);
    }
  };

  useEffect(() => {
    fetchCommodities();
  }, []);

  const handleSearchChange = (event) => {
    const value = event.target.value.toLowerCase();
    setSearchText(value);
    if (!value) {
      setLoading(true);
      fetchCommodities();
    }
  };

  const filteredCommodities = commodities.filter(
    (commodity) =>
      commodity.name?.toLowerCase().includes(searchText.toLowerCase()) ||
      commodity.qty?.toString().includes(searchText) ||
      commodity.price?.toString().includes(searchText) ||
      commodity.uom?.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleBuy = (commodity) => {

    setSelectedCommodity(commodity);
    setOpen(true); // Open the modal to accept phone number
  };

  const triggerSTKPush = async () => {
    try {
      const response = await axios.post(
        "https://theyard.accentrixcloud.com/stk_push",
        {
          phone_number: user.phoneNumber,
          amount: amount,
        }
      );
      console.log("STK Push initiated:", response.data);
      setOpen(false); // Close the modal after successful operation
    } catch (error) {
      console.error("Error initiating STK Push:", error);
    }
  };

  const handleShare = (commodity) => {
    setSelectedCommodity(commodity);
    setShareModalOpen(true); // Open the share modal
  };

  const generateShareMessage = () => {
    if (!selectedCommodity) return "";
    const { name, price, qty, uom } = selectedCommodity;
    return `Check out this trade: ${name} - ${qty} ${uom} available at Ksh ${price} per unit. Click the link to purchase: https://trading.accentrixcloud.com/trades/${selectedCommodity.id}`;
  };

  const shareOnPlatform = (platform) => {
    const message = generateShareMessage();
    const encodedMessage = encodeURIComponent(message);

    let url = "";
    switch (platform) {
      case "whatsapp":
        url = `https://wa.me/?text=${encodedMessage}`;
        break;
      case "facebook":
        url = `https://www.facebook.com/sharer/sharer.php?u=${encodedMessage}`;
        break;
      case "tiktok":
        url = `https://www.tiktok.com/share?text=${encodedMessage}`;
        break;
      default:
        break;
    }

    window.open(url, "_blank");
    setShareModalOpen(false); // Close share modal after sharing
  };

  const calculateQuantity = () => {
    if (!amount || !selectedCommodity) return 0;
    const unitPrice = selectedCommodity.price; // Price per unit
    return (parseFloat(amount) / unitPrice).toFixed(2); // Calculate quantity and round to 2 decimals
  };

  const formatPrice = (price) => {
    return parseFloat(price).toLocaleString("en-US", { minimumFractionDigits: 2 });
  };

  const handleRowClick = (id) => {
    navigate(`/trades/${id}`);
  };


  return (
    <React.Fragment>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography color="primary" variant="h6" component="div">
          Live Trades
        </Typography>
      </Box>

      {loading ? ( // Show spinner when loading
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: "bold" }}>Name</TableCell>
                <Hidden smDown>
                  <TableCell sx={{ fontWeight: "bold" }}>Qty</TableCell>
                </Hidden>
                <TableCell sx={{ fontWeight: "bold" }}>Price (Ksh)</TableCell>
                <TableCell sx={{ fontWeight: "bold" }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredCommodities.map((commodity) => (
                <TableRow
                  key={commodity.id}
                  sx={{
                    "&:hover": { backgroundColor: "#f9f9f9" },
                  }}
                >
                  <TableCell
                    sx={{ cursor: "pointer", color: "#333" }}
                    onClick={() => handleRowClick(commodity.id)}
                  >
                    <Link>{commodity.name}</Link>
                  </TableCell>

                  <Hidden smDown>
                    <TableCell sx={{ color: "#666" }}>
                      {`${commodity.qty} ${commodity.uom}`}
                    </TableCell>
                  </Hidden>
                  <TableCell sx={{ color: "#666" }}>
                    {formatPrice(commodity.price)}
                  </TableCell>

                  <TableCell>
                    <Button
                      onClick={(e) => {
                        e.stopPropagation();
                        handleBuy(commodity);
                      }}
                      sx={{
                        bgcolor: "#df326f",
                        color: "white",
                        "&:hover": { bgcolor: "#c3005d" },
                      }}
                      size="small"
                    >
                      Buy
                    </Button>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        handleShare(commodity);
                      }}
                      sx={{ color: "primary.main", ml: 1 }}
                    >
                      <ShareIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Modal for Purchase */}
      {/* Modal for Purchase */}
<Modal
  open={open}
  onClose={() => setOpen(false)}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="div">
      Complete Purchase
    </Typography>
    <TextField
      fullWidth
      label="Phone Number"
      variant="outlined"
      value={user.phoneNumber}
      sx={{ mt: 2 }}
    />
    <TextField
      fullWidth
      label="Amount"
      variant="outlined"
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
      sx={{ mt: 2 }}
    />
    {amount && selectedCommodity && (
      <Typography sx={{ mt: 1, color: "text.secondary" }}>
        Quantity: {calculateQuantity()} {selectedCommodity.uom}
      </Typography>
    )}
    <Button
      onClick={triggerSTKPush}
      sx={{
        mt: 2,
        bgcolor: "#df326f",
        color: "white",
        "&:hover": { bgcolor: "#c3005d" },
      }}
      fullWidth
    >
      Pay with M-Pesa
    </Button>
  </Box>
</Modal>



      {/* Modal for Sharing */}
<Modal
  open={shareModalOpen}
  onClose={() => setShareModalOpen(false)}
  aria-labelledby="share-modal-title"
  aria-describedby="share-modal-description"
>
  <Box sx={style}>
    <Typography id="share-modal-title" variant="h6" component="div">
      Share Trade
    </Typography>
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        mt: 3,
      }}
    >
      {/* WhatsApp Share */}
      <IconButton
        onClick={() => shareOnPlatform("whatsapp")}
        sx={{ color: "#25D366", fontSize: "32px" }}
      >
        <i className="fab fa-whatsapp"></i> {/* Font Awesome WhatsApp */}
      </IconButton>

      {/* Facebook Share */}
      <IconButton
        onClick={() => shareOnPlatform("facebook")}
        sx={{ color: "#4267B2", fontSize: "32px" }}
      >
        <i className="fab fa-facebook"></i> {/* Font Awesome Facebook */}
      </IconButton>

      {/* TikTok Share */}
      <IconButton
        onClick={() => shareOnPlatform("tiktok")}
        sx={{ color: "#000000", fontSize: "32px" }}
      >
        <i className="fab fa-tiktok"></i> {/* Font Awesome TikTok */}
      </IconButton>
    </Box>
  </Box>
</Modal>

    </React.Fragment>
  );
}
